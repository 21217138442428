@import '../../../constants/variables';

.revenue-report-list {
  .revenue-recognition-report-billed-account-col {
    border-right-width: 1px;
  }

  .revenue-recognition-report-billed-total-col {
    border-left-width: 1px;
  }

  tr {
    border: none;

    td {
      border-bottom: none;
    }
  }

  tr:last-of-type {
    td {
      border-bottom: 1px solid;
    }
  }

  th {
    border-top: 1px solid black;
  }

  .revenue-recognition-report-billed-category-col, .revenue-recognition-report-billed-total-col, .revenue-recognition-report-billed-account-col {
    font-weight: bold;
  }


  tr.revenue-recognition-category-row:not(:last-of-type) {
    td {
      border-bottom: none;
    }
  }

  tr.revenue-recognition-category-row:last-of-type {
    td {
      border-top: 1px solid;
      font-weight: bold;
    }
  }
}
