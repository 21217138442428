@import '../../../constants/variables';

.monthly-incremental-report-list {
  .monthly-incremental-report-billed-type-col{
    border-right-width: 1px;
  }
  .monthly-incremental-report-billed-total-col {
    border-left-width: 1px;
  }
  .monthly-incremental-report-billed-category-col, .monthly_incremental-report-billed-account-col, .monthly-incremental-report-billed-type-col, .monthly-incremental-report-billed-total-col {
    font-weight: bold;
  }

  tr {
    border: none;

    td {
      border-bottom: none;
    }
  }

  tr:last-of-type {
    td {
      border-bottom: 1px solid;
    }
  }

  th {
    border-top: 1px solid black;
  }

  tr.monthly-incremental-totals-row {
    td {
      border-top: 1px solid black;
    }

    .revenue-report-billed-category-col, .revenue-report-billed-total-col {
      font-weight: bold;
    }
  }

  tr.monthly-incremental-category-row:not(:last-of-type) {
    td {
      border-bottom: none;
    }
  }

  tr.monthly-incremental-report-row:last-of-type {
    td {
      border-top: 1px solid;
      font-weight: bold;
    }
  }
}
